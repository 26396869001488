import React, { HTMLProps } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/lobster";
import "@fontsource/asap";
import "@fontsource/roboto";
import "@fontsource/open-sans";


const removeTrailingSlash = (str: string): string => {
  if (str[str.length - 1] === "/") {
    return str.slice(0, -1);
  }

  return str;
}

const isActive = (currentPath: string): boolean => {
  if (typeof window === "undefined")  return false;

  const pathname: string = window.location.pathname;

  if (removeTrailingSlash(currentPath) === removeTrailingSlash(pathname)) {
    return true;
  }

  return false;
};

export const CansativaOrganics = (): JSX.Element => (
  <h1 className="text-4xl text-cansativa-organics-gray-50">
    <span className="flex space-x-3 items-end" style={{ fontFamily: "Lobster" }}>
    <StaticImage
        alt="Marijuana Leaf"
        className="md:ml-2"
        src="../../../../images/cansva-organics-leaf.svg"
        loading="eager"
        objectFit="cover"
        width={40}
      />
      Cansativa Organics
    </span>
  </h1>
);

const NavigationLink = ({ children, href }: HTMLProps<HTMLAnchorElement>): JSX.Element => {
  if (!href) {
    href = "#";
  }

  return (
    <Link
      to={href}
      className={isActive(href)
        ? "font-semibold text-xl bg-cansativa-organics-emerald-background px-2 py-1 outline-none focus:ring-4 focus:ring-white rounded flex flex-wrap sm:inline"
        : "font-semibold text-xl px-2 py-1 outline-none focus:ring-4 rounded flex flex-wrap sm:inline"
      }
    >
      {children}
    </Link>
  );
};

export const Navigation = (): JSX.Element => {
  return (
    <div className="flex flex-col flex-wrap space-y-3 md:space-x-3 md:flex-row md:justify-between md:items-center lg:space-x-12 py-6 h-full bg-cansativa-organics-emerald-800 text-cansativa-organics-gray-50 w-full px-2">
      <div className="">
        <CansativaOrganics />
      </div>
      <div className="" style={{ fontFamily: "Asap" }}>
        <NavigationLink href="/article/cbd/cansativa-organics">Home</NavigationLink>
        <NavigationLink href="/article/cbd/cansativa-organics/about-cbd">About CBD</NavigationLink>
        <NavigationLink href="/article/cbd/cansativa-organics/products">Products</NavigationLink>
        <NavigationLink href="/article/cbd/cansativa-organics/about-us">About Us</NavigationLink>
        <NavigationLink href="/article/cbd/cansativa-organics/contact-us">Contact</NavigationLink>
        <NavigationLink><i className="far fa-search" /></NavigationLink>
        <NavigationLink><i className="fal fa-shopping-basket" /></NavigationLink>
      </div>
    </div>
  );
};

export const Footer = (): JSX.Element => {
  return (
    <div className="flex flex-col flex-wrap space-y-3 md:space-x-3 md:flex-row md:justify-between md:items-center lg:space-x-12 py-6 h-full bg-cansativa-organics-emerald-800 text-cansativa-organics-gray-50 w-full px-2">
      <div className="">
        <CansativaOrganics />
      </div>
      <div className="font-bold text-2xl" style={{ fontFamily: "Roboto" }}>
        <a href="#">www.Cansativa.com</a>
      </div>
      <div className="flex flex-wrap flex-col pr-6" style={{ fontFamily: "Open Sans" }}>
        <span><i className="far fa-envelope pr-2"></i>info@cansativa.com</span>
        <span><i className="far fa-phone-alt pr-2"></i>800-555-1268</span>
      </div>
    </div>
  );
};

export const Date = (): JSX.Element => {
  return (
    <div className="mx-6 mt-8 flex justify-between lg:w-3/5" style={{ fontFamily: "Open Sans" }}>
      <p><i className="far fa-copyright mr-2" />2019</p>
      <p>Last updated March 2020</p>
    </div>
  );
};

const Jumbotron = (): JSX.Element => {
  return (
    <div className="flex flex-col md:flex-row" style={{ maxHeight: "750px" }}>
      <StaticImage
        alt="Marijuana Plant."
        className="md:flex-col md:w-2/3"
        src="../../../../images/cansativa-plant.jpg"
        loading="eager"
        objectFit="cover"
      />
      <div className="flex-col justify-center flex text-center md:w-1/3 p-4">
        <h2 className="text-4xl" style={{ fontFamily: "Lobster" }}>Cansativa Organics</h2>
        <span className="" style={{ fontFamily: "Open Sans" }}>- Founded in 2018 -</span>
        <span className="font-bold text-lg" style={{ fontFamily: "Roboto" }}>www.Cansativa.com</span>
        <div className="text-3xl mt-6 justify-center flex flex-wrap" style={{ fontFamily: "Open Sans" }}>
          <span>Your source</span>
          <span>for CBD information</span>
          <span>and products</span>
        </div>
        <div>
          <button className="mt-8 bg-cansativa-organics-purple-800 text-lg text-cansativa-organics-gray-50 font-bold rounded px-4 py-2" style={{ fontFamily: "Asap" }}>SHOP NOW</button>
        </div>
      </div>
    </div>
  );
};

const Body = (): JSX.Element => {
  return (
    <div className="bg-gray-100 mx-8 my-6 text-black" style={{ fontFamily: "Open Sans" }}>
      <h2 className="text-2xl font-bold" style={{ fontFamily: "Asap" }}>What is CBD?</h2>
      <p className="mt-10">Cannabidiol is a natural remedy used for many common ailments.</p>
      <p className="mt-8">Better known as CBD, it is one of over 100 chemical compounds known as cannabinoids found in the cannabis or marijuana plant, <em>Cannabis sativa.</em></p>

      <div className="flex mt-8 flex-wrap lg:flex-nowrap">
        <div className="flex-col w-1/2">
          <p>Tetrahydrocannabinol (THC) is the main psychoactive cannabinoid found in cannabis and causes the sensation of getting “high” that’s often associated with marijuana.</p>
          <p className="mt-8">Because it does not cause the “high” like TCH, CBD is an appealing option for people who are looking for relief from pain and other symptoms without the mind-altering effects of marijuana.</p>
          <p className="mt-8">CBD oil is made by extracting CBD from the cannabis plant, then diluting it with a carrier oil like coconut or hemp seed oil.</p>
          <p className="mt-8">CBD is a perfect solution for solving various issues with minimum side effects, including:</p>
          <ul className="ml-5"style={{ listStyleType: "disc" }}>
            <li>Epilepsy and seizures</li>
            <li>Inflammation</li>
            <li>Different kind of pain</li>
            <li>Mental disorders</li>
            <li>Nausea and migraines</li>
            <li>Depresion and anxiety</li>
          </ul>
        </div>
        <StaticImage
          alt="Marijuana Plant."
          className="flex-col lg:w-1/2 ml-4 my-4"
          src="../../../../images/cansativa-chem.jpg"
          loading="eager"
          objectFit="contain"
        />
      </div>
      <p className="my-8">The FDA approved a CBD-based liquid medication called Epidiolex® for the treatment of two forms of severe childhood epilepsy, Dravet syndrome and Lennox-Gastaut syndrome. Many researchers, including those funded by the National Institutes of Health (NIH), are continuing to explore the possible uses of THC, CBD, and other cannabinoids for medical treatment</p>
    </div>
  );
};

const CansativaOrganicsPage = (): JSX.Element => {
  return (
    <div className="flex flex-col min-h-screen bg-cansativa-organics-gray-100">
      <Navigation />
      <Jumbotron />
      <Body />
      <Date />
      <Footer />
    </div>
  );
};

export default CansativaOrganicsPage;
