import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import "@fontsource/asap";
import "@fontsource/open-sans";

import { Navigation, Footer, Date } from "./index";


const Body = (): JSX.Element => {
  return (
    <div className="bg-gray-100 text-black text-center justify-center min-h-screen relative" style={{ fontFamily: "Open Sans" }}>
      <StaticImage
        alt="Marijuana Leaf"
        className="min-h-screen"
        src="../../../../images/cansative-cbd.jpg"
        loading="eager"
        objectFit="cover"
      />
      <div className="absolute top-0 right-0 w-1/2 md:w-1/3 text-left mt-10 mr-2 md:mt-20 md:mr-10 text-cansativa-organics-gray-800">
        <span className="text-3xl font-bold  mt-4" style={{ fontFamily: "Asap" }}>About Cansativa Organics</span>
        <p style={{ fontFamily: "Open Sans" }}>Ramon and Christina Warner have been interested in the potential benefits of CBD for many years. They founded Cansativa Organics to share their information with others.</p>
      </div>
      <div className="absolute inset-x-0 bottom-0">
        <Date />
      </div>
    </div>
  );
};

const AboutUsPage = (): JSX.Element => {
  return (
    <div className="flex flex-col min-h-screen bg-cansativa-organics-gray-100">
      <Navigation />
      <Body />
      <Footer />
    </div>
  );
};

export default AboutUsPage;
